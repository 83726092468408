<template>
  <!-- 财务账户管理-项目结算确认 -->
  <div class="projectSettlementConfirmation">
    <en-table-layout :tableData="tableData.data" @sort-change="handleSortChange">
      <template slot="toolbar">
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="客户编号" value="customer_no"></el-option>
              <el-option label="所属销售人员" value="sell_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
      </template>
      <template slot="table-columns">
        <el-table-column prop="customer_no" show-overflow-tooltip label="客户编号" fixed="left" />
        <el-table-column prop="customer_name" show-overflow-tooltip label="客户名称" fixed="left" />
        <el-table-column prop="sell_name" show-overflow-tooltip label="所属销售人员" width="150" fixed="left" />
        <el-table-column prop="shop_name" width="150" show-overflow-tooltip label="商城名称" />
        <el-table-column label="项目总金额" show-overflow-tooltip width="100" prop="total_amount">
          <template slot-scope="scope">
            {{ scope.row.total_amount | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="结算次数" width="130" prop="settlement_times" sortable="custom" />
        <el-table-column label="实际已结算金额" show-overflow-tooltip width="150" prop="settlemented_amount" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.settlemented_amount | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="未结算金额" width="130" show-overflow-tooltip prop="residue_amount">
          <template slot-scope="{row}">
            {{ (row.residue_amount || 0) | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" width="200" label="备注信息" show-overflow-tooltip />
        <!--操作-->
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="handleGoodsAudit(row)">结算核销</el-button>
            <el-button size="mini" type="primary" @click="handleRemake(row)">备注</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!-- 结算核销弹窗 -->
    <el-dialog-x-dialog title="结算核销" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="650px" :visible.sync="messageVisible">
      <div style="padding:10px" class="messageTable">
        <el-form class="orderDetail" :model="ordinaryInfo" label-width="140px">
          <p><span>来源应用：</span><span>{{ ordinaryInfo.shop_type | shopTypeFilter }}</span></p>
          <p><span>商城名称：</span><span>{{ ordinaryInfo.shop_name }}</span></p>
          <p><span>定制项目结算日期及金额：</span></p>

          <el-table style="width: 600px" height="300px" border stripe
            :data="ordinaryInfo.customer_customized_project_settlement_dtolist">
            <el-table-column prop="date" label="结算日期" width="180">
              <template slot-scope="{row}">{{ row.settlement_date | unixToDate('yyyy-MM-dd') }}</template>
            </el-table-column>
            <el-table-column width="110" prop="settlement_amount" label="预计结算金额" />
            <el-table-column width="110" prop="real_settlement_amount" label="实际结算金额">
              <template slot-scope="{row}">
                {{ row.settlemented == 1 ? row.real_settlement_amount : '——' }}
              </template>
            </el-table-column>
            <el-table-column prop="settlemented" label="是否结算">
              <template slot-scope="{row}">{{ row.settlemented == 1 ? '已结算' : '未结算' }}</template>
            </el-table-column>
            <!--操作-->
            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="{row}">
                <el-button v-if="row.settlemented == 1" size="mini" type="primary"
                  @click="handlechildDetail(row)">查看</el-button>
                <el-button v-else size="mini" type="primary" @click="handleAudit(row)">核销</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
    </el-dialog-x-dialog>

    <!-- 备注 -->
    <x-dialog :proxy="remakeDialog">
      <el-form style="padding:20px" :model="remarkForm" ref="remarkForm">
        <el-form-item style="margin: 0">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 5 }" maxlength="100" placeholder="请输入100字以内的备注信息"
            v-model="remarkForm.remarks"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!--核销表单-->
    <x-dialog :proxy="examinationDialog">
      <el-form style="padding:20px" :model="goodsAuditForm" :rules="goodsAuditFormRules" ref="goodsAuditForm"
        label-width="130px">
        <el-form-item label="客户名称" prop="customer_name">
          <span>{{ ordinaryInfo.customer_name }}</span>
        </el-form-item>
        <el-form-item label="项目总金额" prop="total_amount">
          <span>{{ ordinaryInfo.total_amount | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="本次应结算金额" prop="settlement_amount">
          <span>{{ goodsAuditForm.settlement_amount | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="本次实结算金额" prop="real_settlement_amount">
          <el-input style="width: 200px" v-model.trim="goodsAuditForm.real_settlement_amount">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="图片凭证" prop="pass">
          <el-upload :class="{ uoloadSty: showBtnDealImg, disUoloadSty: noneBtnImg }" accept=".jpg, .png, .jpeg"
            list-type="picture-card" class="upload-demo" :action="`${MixinUploadApi}?scene=goods`" multiple
            :before-upload="beforeUpload" :limit="3" :on-success="imgUploadSuccess" :on-remove="imgHandleRemove"
            :show-file-list="true">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">提示：请上传大小不超10M，格式为jpg / png的图片，最多3张。</div>
          </el-upload>
          <!-- <ul v-if="goodsAuditForm.image_voucher.length" class="el-upload-list el-upload-list--picture-card">
            <li class="el-upload-list__item is-success animated" v-for="(item, index) in goodsAuditForm.image_voucher"
              :key="index" style="margin-right:10px;width:80px;height:80px;">
              <img :src="item.url" alt="" class="el-upload-list__item is-success" style="width: 100%;height: 100%;">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-delete" @click="imgHandleRemove(index)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </li>
          </ul> -->
        </el-form-item>
        <el-form-item label="收款备注" prop="remark">
          <el-input type="textarea" :rows="4" placeholder="请输入100字以内的备注信息。" v-model.trim="goodsAuditForm.remark"
            maxlength="100"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!-- 查看操作 -->
    <x-dialog :proxy="lookDialog">
      <el-form :model="detailInfo" label-width="120px">
        <el-form-item label="客户名称" prop="customer_name">
          <span>{{ ordinaryInfo.customer_name }}</span>
        </el-form-item>
        <el-form-item label="项目总金额" prop="cash_money">
          <span>{{ ordinaryInfo.total_amount | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="本次应结算金额" prop="cash_money">
          <span>{{ detailInfo.settlement_amount | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="本次实结算金额" prop="real_settlement_amount">
          <span>{{ detailInfo.real_settlement_amount | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="图片凭证" v-if="detailInfo.image_voucher">
          <div style="display: flex;">
            <img style="width:100px;height:100px;margin: 0 5px 20px;"
              v-for="(item, index) in JSON.parse(detailInfo.image_voucher)" @click="handleImgLook(item)" :key="index"
              :src="item.url" alt class="pass_img" />
          </div>
        </el-form-item>
        <el-form-item label="收款备注" prop="remark">
          <span>{{ detailInfo.remark || "无" }}</span>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!-- 查看大图 -->
    <el-dialog :visible.sync="centerDialogVisible" modal close-on-click-modal custom-class="dialog">
      <el-carousel :autoplay="false" arrow="never">
        <el-carousel-item v-for="(item, index) in centerDialogList" :key="index">
          <img :src="item.url" style="width: 100%; height: 100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_settingCustomer from '@/api/settingCustomer';
import { RegExp } from '@/../ui-utils';
export default {
  filters: {
    shopTypeFilter (shopType) {
      const temp = {
        2: '现金商城',
        4: '智能套餐卡商城',
        5: '双选商城',
        6: '小程序商城'
      }
      return temp[shopType]
    }
  },
  name: 'projectSettlementConfirmation',
  components: { EnTableLayout, XDialog, },
  data () {
    return {
      showBtnDealImg: true,
      noneBtnImg: false,
      limitCountImg: 3,   //上传图片的最大数量
      key_word: 'customer_name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        keyWord: "",
      },
      // 列表数据
      tableData: {
        data: []
      },
      messageVisible: false,//打开结算核销弹窗
      ordinaryInfo: {},
      //查看操作
      lookDialog: $xDialog.create({
        title: '查看操作',
        wrapperClass: 'show-withdrawal-info',
        width: '50vw',
        disableCancel: true,
        disableConfirm: true
      }),
      detailInfo: {},
      //核销操作
      examinationDialog: $xDialog.create({
        title: "核销操作",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm

      }),
      //备注
      remakeDialog: $xDialog.create({
        title: '备注说明',
        wrapperClass: 'show-withdrawal-info',
        width: '50vw',
        displayFooterExtras: true,
        beforeConfirm: this.submitRemark
      }),
      goodsAuditForm: {
        customer_id: '',
        real_settlement_amount: '',
        image_voucher: []
      },
      goodsAuditFormRules: {
        real_settlement_amount: [
          { required: true, message: '请填写实际结算金额', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              console.log(Math.abs(value));
              console.log(value);
              if (value > 1000000) {
                callback(new Error('最大值为1000000.00'))
              } else if (!RegExp.money.test(value) || Number(value) <= 0 || Math.abs(value) != value) {
                callback(new Error("请输入正确的金额"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ]
      },
      remarkForm: {//备注信息表单
        remarks: ''
      },
      centerDialogVisible: false, // 图片预览器
      centerDialogList: [],
    }
  },
  mounted () {
    this.GET_GoodsAuditList()
  },
  methods: {
    /**结算核销-查看 */
    handlechildDetail (row) {
      this.detailInfo = row;
      console.log(row);
      this.lookDialog.display();
    },
    /**结算核销-核销操作 */
    handleAudit (row) {
      this.detailInfo = row;
      this.goodsAuditForm = {
        id: row.id,
        customer_id: row.customer_id,
        settlement_amount: row.settlement_amount,//应结算金额
        real_settlement_amount: row.settlement_amount,//实际结算金额
        image_voucher: [],//图片凭证
        remark: '',//首款备注
      }
      this.examinationDialog.display();
    },
    /**提交备注信息 */
    submitRemark () {
      let { promise, resolve, reject } = Promise.withResolvers();
      API_settingCustomer.getCustomerDiscountRemarks(this.remarkForm).then(response => {
        this.$message.success('提交成功');
        this.GET_GoodsAuditList();
        resolve(true)
      });
      return promise
    },
    beforeUpload (file) {
      if (!file.type.includes('image')) {
        this.$message.error('只能上传图片！');
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        this.$message.error('请上传10M以内，格式为jpg/png的图片');
      }
      // else {
      //   this.loadingInstance = this.$loading({
      //     text: '上传中',
      //   });
      // }
      return isLt5M;
    },
    //上传图片
    imgUploadSuccess (response, file, fileList) {
      this.goodsAuditForm.image_voucher.push({ url: response.url })
      this.noneBtnImg = this.goodsAuditForm.image_voucher.length >= this.limitCountImg;
    },
    //删除图片
    imgHandleRemove (file) {
      this.goodsAuditForm.image_voucher.forEach((item, index) => {
        if (item.url == file.response.url) {
          this.goodsAuditForm.image_voucher.splice(index, 1);
        }
      })
      this.noneBtnImg = this.goodsAuditForm.image_voucher.length >= this.limitCountImg;
    },
    /**查看 */
    openDetail (row) {
      this.lookDialog.display();
    },
    /**排序事件 */
    handleSortChange ({
      prop,
      order
    }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.GET_GoodsAuditList();
    },
    /**打开备注信息 */
    handleRemake (row) {
      this.remakeDialog.display();
      this.remarkForm = {
        id: row.id,
        remarks: ''
      }
    },
    /**提交充值表单 */
    submitGoodsAuditForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.goodsAuditForm.validate((valid) => {
        if (valid) {
          let goodsAuditForm = {
            ...this.goodsAuditForm,
          }
          goodsAuditForm.image_voucher = JSON.stringify(goodsAuditForm.image_voucher)
          goodsAuditForm.real_settlement_amount = Number(goodsAuditForm.real_settlement_amount)
          delete goodsAuditForm.settlement_amount;
          API_settingCustomer.setCcpsAudit(goodsAuditForm).then((res) => {
            this.$message.success('保存成功');
            if (!this.ordinaryInfo.id) return this.$message.error('请刷新当前页面')
            API_settingCustomer.getModelAndDetail(this.ordinaryInfo.id).then(res2 => {
              this.ordinaryInfo = res2;
            })
            resolve(true)
          });
        } else {
          reject(false)
        }
      });
      return promise
    },
    /** 打开结算核销 */
    async handleGoodsAudit (row) {
      this.messageVisible = true;
      const datas = await API_settingCustomer.getModelAndDetail(row.id)
      if (!datas.id) return this.$message.error('请刷新当前页面')
      this.ordinaryInfo = datas;
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    /** 获取列表数据 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      API_settingCustomer.getCustomerDiscountList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
    //图片预览
    handleImgLook (url) {
      console.log(url);
      this.centerDialogList = [url];
      this.centerDialogVisible = true;
    },

  }
}
</script>
<style lang="scss" scoped>
/deep/ .uoloadSty .el-upload--picture-card {
  width: 82px;
  height: 82px;
  line-height: 82px;
}

/deep/ .disUoloadSty .el-upload--picture-card {
  display: none;
  /* 上传按钮隐藏 */
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 82px;
  height: 82px;
}
</style>